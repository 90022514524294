.App, .Create {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

h1, p {
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 100px);
  grid-template-rows: repeat(5, 100px);
  gap: 5px;
}

.row {
  display: contents; /* Using display: contents to make each cell take full grid space */
}

textarea {
  resize: none;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  padding: 0; /* Remove padding */
  overflow: hidden; /* Ensure textarea fits within cell */
}

.cell textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  text-align: center;
  font-size: 14px;
  white-space: pre-wrap; /* Allow text wrapping */
  word-break: break-word; /* Ensure words break to fit */
  overflow-wrap: break-word; /* Ensure long words break to fit */
  resize: none; /* Prevent resizing */
  background: none; /* Remove background for better integration */
  outline: none; /* Remove default outline for better appearance */
  padding: 5px; /* Add padding inside the textarea */
}

input[type="text"] {
  padding: 10px;
  margin: 10px 0;
  width: 300px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.celebration {
  margin-top: 20px;
  font-size: 24px;
  color: #ff4500;
  text-align: center;
}
