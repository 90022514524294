.App{
  text-align: center;
  position: relative;
  font-family: 'Times New Roman', Times, serif;
}

.grid {
  display: inline-block;
  margin: 0 auto;
}

.row {
  display: flex;
}

.cell {
  width: 100px;
  height: 100px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  font-size: 1em; /* 默认字体大小 */
}

.cell.marked {
  background-color: #90ee90;
}

.celebration {
  margin-top: 20px;
  font-size: 24px;
  color: #ff4500;
}

/* 添加媒体查询以支持小屏幕 */
@media (max-width: 600px) {
  .cell {
    width: 60px;
    height: 60px;
    font-size: 0.5em; /* 调整小屏幕上的字体大小 */
  }
  .App {
    padding: 10px;
  }
  .celebration {
    font-size: 18px;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  font-size: 0.8em;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* 全屏动画样式 */
.animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  z-index: 1000;
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* 新增的标题和描述部分样式 */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title-description {
  flex-grow: 1;
  text-align: left; /* 确保标题和描述靠左 */
}

.qr-code {
  margin-left: 20px;
}

/* Ensure the grid container is centered */
.grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.language-switcher {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.language-button {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.language-button:focus {
  outline: none;
}

.language-menu {
  position: absolute;
  right: 0;
  top: 35px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 10;
}

.language-menu li {
  padding: 10px 20px;
  cursor: pointer;
}

.language-menu li:hover {
  background-color: #f0f0f0;
}

